<template>
  <div id="page-fournisseur-commande-list">
    <div class="vx-card p-6">

      <div class="vx-col w-full">
        <vs-table v-model="selected" max-items="10" search :data="br" multiple>
          <template slot="header">
            <!-- ACTION - DROPDOWN -->
            <vs-dropdown vs-trigger-click class="cursor-pointer">

              <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
                <span class="mr-2 leading-none">Actions</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>

              <vs-dropdown-menu>

                <vs-dropdown-item>
                  <span class="flex items-center">
                    <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                    <span>Supprimer</span>
                  </span>
                </vs-dropdown-item>

                <vs-dropdown-item>
                  <span class="flex items-center">
                    <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                    <span>CSV</span>
                  </span>
                </vs-dropdown-item>

              </vs-dropdown-menu>
            </vs-dropdown>
            <template v-if="activeUserInfos.privileges.find(item => item === 'nouveau_br')&&activeUserInfos.privileges.find(item => item === 'create')">
            <vs-button
              class="btn-primary ml-4 mb-2 sm:mb-0"
              color="primary"
              @click="newCommande">
              <!-- <feather-icon icon="UserPlusIcon" svgClasses="h-3 w-4" /> -->
              Nouveau BR

            </vs-button>
          </template>
          </template>
          <template slot="thead">
            <vs-th>
              N°
            </vs-th>
            <vs-th sort-key="CodeCmde">
              N° BR
            </vs-th>
            <vs-th sort-key="NomOffic">
              Officine
            </vs-th>
            <vs-th sort-key="RegimeOffic">
              Fournisseur
            </vs-th>
            <vs-th sort-key="status">
              Status
            </vs-th>
            <vs-th>
              Transferer
            </vs-th>
            <vs-th>
              Montant BR
            </vs-th>
            <vs-th>
              Actions
            </vs-th>

          </template>

          <template slot-scope="{data}">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" >
              <vs-td :data="indextr+1">
                {{indextr + 1}}
              </vs-td>

              <vs-td :data="tr.CodeCmde">
                <cell-renderer-link :id="tr._id" :CodeCmde="tr.CodeCmde" />
              </vs-td>
              <vs-td>
                <cell-renderer-officine :officineId="tr.IdOfficine" />
              </vs-td>
              <vs-td>
                <cell-renderer-fournisseur :IdFour="tr.IdFour" />
              </vs-td>
              <vs-td>
                <cell-renderer-status :status="tr.status" />
              </vs-td>
              <vs-td>
                <cell-renderer-transfert :details="tr.details" />
              </vs-td>
              <vs-td>
                <cell-renderer-montant-commande :details="tr.details" />
              </vs-td>

              <vs-td>
                <cell-renderer-actions :details="tr.details" :id="tr._id" :CodeCmde="tr.CodeCmde" :status="tr.status" />
              </vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>

      <pagination class="mt-5" :IdOfficine="activeUserInfos.IdOfficine" :pageOfItems="br" :pager="pager" />
      <!-- <vs-pagination
        :total="totalPages"
        :max="7"
        v-model="currentPage" /> -->

    </div>
  </div>

</template>

<script>
/* lazy loading Vue components */
const vSelect = () => import('vue-select')

// Pagination
const pagination = () => import('./pagination.vue')


// Cell Renderer
const CellRendererLink = () => import('./cell-renderer/CellRendererLink.vue')
const CellRendererStatus = () => import('./cell-renderer/CellRendererStatus.vue')
const CellRendererVerified = () => import('./cell-renderer/CellRendererVerified.vue')
const CellRendererActions = () => import('./cell-renderer/CellRendererActions.vue')
const CellRendererOfficine = () => import('./cell-renderer/CellRendererOfficine.vue')
const CellRendererFournisseur = () => import('./cell-renderer/CellRendererFournisseur.vue')
const CellRendererMontantCommande = () => import('./cell-renderer/CellRendererMontantCommande.vue')
const CellRendererTransfert = () => import('./cell-renderer/CellRendererTransfert.vue')

export default {
  components: {
    vSelect,

    // Cell Renderer
    CellRendererLink,
    CellRendererStatus,
    CellRendererVerified,
    CellRendererActions,
    CellRendererOfficine,
    CellRendererFournisseur,
    CellRendererMontantCommande,
    CellRendererTransfert,
    pagination
  },
  data () {
    return {
      activeUserInfos: JSON.parse(localStorage.getItem('userInfo')) ? JSON.parse(localStorage.getItem('userInfo')) : null,
      page: 1,
      // Filter Options

      selected: []
    }
  },
  computed: {
    untransfertCommande () {
      return this.$store.getters['commande_fourn/untransfertCommande']
    },
    br () {
      return this.$store.state.commande_fourn.pageOfItems
    },
    pager () {
      return this.$store.state.commande_fourn.pager
    }

  },
  methods: {
    newCommande () {
      this.$router.push({ path: '/apps/commandefourn/add' })
    },
    getCommandesFournisseur () {
      this.$store.dispatch('commande_fourn/getCommandesFournisseur')
        .then(() => {

        })
        .catch(err => { console.log(err) })
    },
    getBonReceptionByOfficine (id) {
      const payload = {
        officineId: id,
        Page: this.page
      }
      this.$vs.loading({
        type:'sound'
      })
      this.$store.dispatch('commande_fourn/getBonReceptionByOfficine', payload)
        .then(() => {
          this.$vs.loading.close()
        })
        .catch(err => {
          console.log(err)
          this.$vs.loading.close()
        })

    },
    activeUserInfo () {


    }
  },
  created () {
    if (this.$store.state.commande_fourn.pageOfItems === null)  this.getBonReceptionByOfficine(this.activeUserInfos.IdOfficine)
  }

}

</script>

<style lang="scss">
#page-fournisseur-commande-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}

/* .header-sidebar {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  h4 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    > button {
      margin-left: 10px;
    }
  }
}

.footer-sidebar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  > button {
    border: 0px solid rgba(0, 0, 0, 0) !important;
    border-left: 1px solid rgba(0, 0, 0, 0.07) !important;
    border-radius: 0px !important;
  }
} */
</style>
